import React from 'react';
import { Link, graphql } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../layouts/index';
import PrismicRecipeNav from '../components/PrismicRecipeNav';
import { Card, CardImg } from 'react-bootstrap';
import CardTitle from 'reactstrap/lib/CardTitle';
import CardBody from 'reactstrap/lib/CardBody';
import CardSubtitle from 'reactstrap/lib/CardSubtitle';
import RecipeCard from '../components/RecipeCard';

const Recipes = ({ data }) => {
  return (
    <Layout>
      <SEO title="home"/>
      <div className="intro">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h1>Recipes</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <PrismicRecipeNav/>
        <br/>
        <div className="row">
          {data.allPrismicRecipe.edges.map(({ node }) => (
            <div className="col-md-4 col-sm-6">
              <RecipeCard
                title={node.data.title.text}
                category={node.data.category}
                recipeUid={node.uid}
                featureImg={node.data.featureimg}
                coverImgSize="15em"
              />
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query prismicRecipes {
    allPrismicRecipe (
      sort: { fields: last_publication_date, order: DESC }
      ) {
      edges {
        node {
          id
          uid
          last_publication_date 
          data {
            title {
              text
            }
            featureimg {
              url
            }
            category {
              uid
            }
          }
        }
      }
    }
  }
`;
export default Recipes