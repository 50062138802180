import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Card, CardBody, CardImg, CardSubtitle, CardTitle } from 'reactstrap';


class RecipeCard extends React.Component {
  render() {
    const {
      title,
      featureImg,
      category,
      recipeUid,
      coverImgSize
    } = this.props;
    return (
      <>
        <Card className="mb-4">
          <CardBody>

            <CardTitle>
              <Link to={`/recipes/${category.uid}/${recipeUid}`}>
                {title}
              </Link>
            </CardTitle>
            <CardSubtitle>{category.uid}</CardSubtitle>
          </CardBody>
          <CardImg alt={featureImg.alt} style={{
            objectFit: 'cover',
            height: coverImgSize,
            width: '90%',
          }} src={featureImg.url || '/assets/recipe_default.jpeg'}/>
        </Card>
      </>
    );
  }
};

RecipeCard.defaultProps = {
  recipeUid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  featureImg: PropTypes.object,
  category: PropTypes.object.isRequired,
  coverImgSize: PropTypes.string.isRequired,
};

export default RecipeCard;
