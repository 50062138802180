import React from 'react';

import { StaticQuery, graphql, Link } from 'gatsby';
import { Nav, NavItem, Dropdown, DropdownItem, DropdownToggle, DropdownMenu, NavLink } from 'reactstrap';

const PrismicRecipeNav = (props) => (
  <StaticQuery
    query={categoiresQuery}
    render={(data) => {
      return (
        <Nav tabs>
          {data.allPrismicRecipeType.edges.map(({ node }) => (
            <NavItem>
              <Link
                className="nav-link"
                to={`/recipes/${node.uid}/`}
                activeClassName="active"
                partiallyActive>{node.data.title.text}</Link>
            </NavItem>
          ))}
       </Nav>
      );
    }
  }
  />
);

PrismicRecipeNav.defaultProps = {
  categories: [],
};

export default PrismicRecipeNav;

const categoiresQuery = graphql`
query {
  allPrismicRecipeType(sort: {fields: data___link_order, order: ASC}) {
    edges {
      node {
        uid
        data {
          title {
            text
          }
        }
      }
    }
  }
}
`;
